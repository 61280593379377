import React from 'react'
import styled, { css } from 'styled-components'

import './glow-on-hover.css'

const StyledLinkButton = styled(({ isIconButton, ...rest }) => <a {...rest} />)`
  background: transparent;
  color: white;
  margin: 0.25rem;
  padding: ${props => (props.isIconButton ? '.5rem' : '.75rem 1rem')};
  ${props =>
    props.isIconButton &&
    css`
      height: 2rem;
      width: 2rem;
    `}

  font-weight: bold;
  text-decoration: none;

  & svg {
    fill: white;
    height: 1rem;
    width: 1rem;
  }

  &:hover {
    background: var(--color_bg_button);
    border-radius: 10px;
    color: var(--color_text_overBgButton);
  }

  &:hover svg {
    fill: var(--color_text_overBgButton);
  }

  &:active {
    text-shadow: 1px 1px white;
  }
`

const LinkButton = ({ href, isIconButton, children }) => (
  <StyledLinkButton className="glow-on-hover" href={href} isIconButton={isIconButton} target="_self">
    {children}
  </StyledLinkButton>
)

const ExternalLinkIconButton = ({ href, Icon, fill }) => (
  <StyledLinkButton className="glow-on-hover" href={href} isIconButton={true} target="_blank" rel="noopener">
    <Icon style={{ fill }} />
  </StyledLinkButton>
)

export default LinkButton
export { ExternalLinkIconButton }
