import React from 'react'
import styled from 'styled-components'

import servicesIcon from '../assets/icons/automation.png'
import servicesImg from '../assets/pics/services.png'
import smallServicesImg from '../assets/pics/services-small.jpg'

import Page from '../components/Page'
import Banner from '../components/Banner'
import PageCTASection from '../components/PageCTASection'

import { useLangStoreData } from '../utils/LangStore'

import { PAGES, ROUTES, UI } from '../constants'

const ServicesPage = () => {
  return (
    <Page active={PAGES}>
      <ServicesBanner />
      <ServicesIntro />
      <ServicesProducts />
      <ServicesCTASection />
    </Page>
  )
}

export default ServicesPage

const ServicesBanner = () => {
  const { t } = useLangStoreData()
  return (
    <Banner
      title={t('services__title')}
      subTitle={t('services__subTitle')}
      imgSrc={servicesImg}
      smallImgSrc={smallServicesImg}
    />
  )
}

const ServicesIntroStyled = styled.section`
  background-color: white;
`

const ServicesIntroTitle = styled.div`
  line-height: 1.5;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  margin: 0 auto;
`

const ServicesIntroContent = styled.div`
  position: relative;
  z-index: 2;

  margin-top: var(--s2);
`

const ServicesIntroContainer = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  gap: var(--s2);
  
  border-radius: var(--radius);

  @media (max-width: ${UI.TABLET}) {
    & {
      flex-direction: column;
    }
  }
`

const ServicesIntroIcon = styled.img`
  width: 40%;

  @media (max-width: ${UI.TABLET}) {
    & {
      max-width: 50%;
      flex-direction: column;
    }
  }
`

const ServicesIntroText = styled.div`
  font-size: 18px;
  max-width: 70ch;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const ServicesIntroParagraph = styled.p`
  line-height: 1.5;
`

const ServicesIntro = () => {
  const { t } = useLangStoreData()
  return (
    <ServicesIntroStyled className='section'>
      <ServicesIntroTitle className='title'>{t('services__introTitle')}</ServicesIntroTitle>
      <ServicesIntroContent>
        <ServicesIntroContainer className='container'>
          <ServicesIntroText>
            <ServicesIntroParagraph>{t('services__introText1')}</ServicesIntroParagraph>
            <ServicesIntroParagraph>{t('services__introText2')}</ServicesIntroParagraph>
            <ServicesIntroParagraph>{t('services__introText3')}</ServicesIntroParagraph>
            <ServicesIntroParagraph>{t('services__introText4')}</ServicesIntroParagraph>
          </ServicesIntroText>
          <ServicesIntroIcon src={servicesIcon} alt='Services Icon' />
        </ServicesIntroContainer>
      </ServicesIntroContent>
    </ServicesIntroStyled>
  )
}

const ServicesProductsStyled = styled.section`
  background-color: #E8EDF3;
`

const ServicesProductsTitle = styled.h2`
  font-size: 28px;
`

const ServicesProductsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  column-gap: var(--s0);
  row-gap: var(--s0);
  margin-top: var(--s1);

  @media (max-width: 1199px) {
    & {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (max-width: ${UI.TABLET}) {
    & {
      justify-items: center;
      grid-template-columns: 1fr;
    }
  }
`

const ServicesProductsItem = styled.div`
  background-color: white;
  border-radius: var(--radius);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
  padding: var(--s0);

  max-width: 550px;
  min-height: 184px;
  border-top: 3px solid white;

  &:hover {
    border-top: 3px solid var(--color-blue-cta);
  }
`

const ServicesProductsItemTitle = styled.div`
  line-height: 1.5;
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 20px;
`

const ServicesProductsItemDescription = styled.div`
  line-height: 1.5;
  font-size: 18px;
`

const PRODUCTS = [
  { key: 'services__productsCustomSoftware' },
  { key: 'services__productsProcessWeb' },
  { key: 'services__productsProcessAutomation' },
  { key: 'services__productsIOT' },
  { key: 'services__productsESD' },
  { key: 'services__productsSPD' },
  { key: 'services__productsSystemIntegration' },
  { key: 'services__productsLegacyModernization' },
  { key: 'services__productsSoftwareAudit' }
]

const ServicesProducts = () => {
  const { t } = useLangStoreData()
  return (
    <ServicesProductsStyled className='section'>
      <ServicesProductsTitle className='section-title'>{t('services__productsTitle')}</ServicesProductsTitle>
      <ServicesProductsContainer className='container'>
        {PRODUCTS.map(product =>
          <ServicesProductsItem key={product.key}>
            <ServicesProductsItemTitle>{t(product.key + 'Title')}</ServicesProductsItemTitle>
            <ServicesProductsItemDescription>{t(product.key + 'Description')}</ServicesProductsItemDescription>
          </ServicesProductsItem>
        )}
      </ServicesProductsContainer>
    </ServicesProductsStyled>
  )
}

const ServicesCTASection = () => {
  const { t } = useLangStoreData()
  return (
    <PageCTASection
      title={t('services__ctaTitle')}
      buttonText={t('services__ctaButton')}
      url={ROUTES.processPage}
    />
  )
}
