import React from 'react'
import styled from 'styled-components'

import Page from '../components/Page'
import Banner from '../components/Banner'
import PageCTASection from '../components/PageCTASection'

import { useLangStoreData } from '../utils/LangStore'

import aboutUsImg from '../assets/pics/about-us.png'
import smallAboutUsImg from '../assets/pics/about-us-small.jpg'
import helpYouImg from '../assets/icons/help-you.png'

import { ROUTES, UI } from '../constants'

const AboutUsPage = () => {
  return (
    <Page>
      <AboutUsBanner />
      <AboutUsQuestions />
      <AboutUsHelpYou />
      <AboutUsExpectations />
      <AboutUsCTA />
    </Page>
  )
}

export default AboutUsPage

const AboutUsBanner = () => {
  const { t } = useLangStoreData()
  return (
    <Banner
      title={t('aboutUS__title')}
      subTitle={t('aboutUS__subTitle')}
      imgSrc={aboutUsImg}
      smallImgSrc={smallAboutUsImg}
    />
  )
}

const Questions = styled.section`
  --ribbon-height: 150px;

  position: relative;
  padding-top: var(--s2);
  background-color: var(--color-blue-light);
`

const QuestionPrimaryRibbon = styled.div`
  background-color: var(--color-blue);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  
  border-top-right-radius: var(--radius);
  border-bottom-right-radius: var(--radius);

  height: var(--ribbon-height);
  width: 90%;

  position: absolute;
  top: var(--s2);
  left: 0;
`

const QuestionPrimary = styled.h2`
  height: var(--ribbon-height);

  display: flex;
  align-items: center;

  font-size: 28px;
  color: white;
  font-weight: 700;
  width: 90%;

  padding-right: var(--s1);

  @media (max-width: ${UI.TABLET}) {
    & {
      font-size: 26px;
    }
  }

  @media (max-width: ${UI.MOBILE}) {
    & {
      font-size: 20px;
      padding-right: var(--s0);

    }
  }
`

const QuestionContainer = styled.div`
  margin-top: var(--s2);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 32px 64px;
`

const QuestionItem = styled.div`
    font-size: 22px;
    color: var(--color-blue);
    width: 300px;
    line-height: 1.5;

    @media (max-width: ${UI.MOBILE}) {
      & {
        font-size: 20px;
      }
    }
`

const AboutUsQuestions = () => {
  const { t } = useLangStoreData()

  return (
    <Questions className='section'>
      <QuestionPrimaryRibbon />
      <QuestionPrimary className='container relative'>{t('aboutUS__questionPrimary')}</QuestionPrimary>
      <QuestionContainer className='container'>
        <QuestionItem>{t('aboutUS__questionSecundary1')}</QuestionItem>
        <QuestionItem>{t('aboutUS__questionSecundary2')}</QuestionItem>
      </QuestionContainer>
    </Questions>
  )
}


const HelpYou = styled.section`
  background-color: var(--color-blue-dark);
  color: white;
`

const HelpYouContainer = styled.div``

const HelpYouTitle = styled.h2`
  font-size: 32px;
  color: white;
  font-weight: 700;
  margin-bottom: 35px;

  @media (max-width: ${UI.MOBILE}) {
    & {
      font-size: 26px;
    }
  }
`
const HelpYouContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  flex-wrap: wrap;

  font-size: 20px;

  @media (max-width: ${UI.TABLET}) {
    & {
      font-size: 18px;
      flex-direction: column;
    }
  }
`

const HelpYouImage = styled.img`
  max-width: 200px;
  height: auto;

  @media (max-width: ${UI.TABLET}) {
    & {
      max-width: 150px;
    }
  }
`

const HelpYouTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;

  flex: 1;
`

const HelpYouText = styled.div`
  letter-spacing: 1.6px;
  line-height: 1.5;
`

const AboutUsHelpYou = () => {
  const { t } = useLangStoreData()

  return (
    <HelpYou className='section'>
      <HelpYouContainer className='container'>
        <HelpYouTitle className='title'>{t('aboutUS__helpTitle')}</HelpYouTitle>
        <HelpYouContent>
          <HelpYouTextContainer>
            <HelpYouText>{t('aboutUS__helpText1')}</HelpYouText>
            <HelpYouText>{t('aboutUS__helpText2')}</HelpYouText>
            <HelpYouText dangerouslySetInnerHTML={{ __html: t('aboutUS__helpText3') }} />
          </HelpYouTextContainer>
          <HelpYouImage src={helpYouImg} alt='Trust Icon' />
        </HelpYouContent>
      </HelpYouContainer>
    </HelpYou>
  )
}

const Expectations = styled.section`
  background-color: var(--color-blue-light);
`

const ExpectationsContainer = styled.div``

const ExpectionsTitle = styled.h2`
  font-size: 32px;
  font-weight: 700;
  color: var(--color-blue-dark);
  text-align: center;
  margin-bottom: var(--s2);

  @media (max-width: ${UI.TABLET}) {
    font-size: 26px;
  }
`

const ExpectationsCards = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 32px;
  aling-items: center;
  flex-wrap: wrap;
`

const ExpectationCardItem = styled.div`
  background-color: white;
  border-radius: var(--radius);
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);

  max-width: 450px;

  display: flex;
  flex-direction: column;
  gap: 25px;

  padding: 20px 28px;
`

const ExpectationCardItemTitle = styled.div`
  font-size: 24px;
  color: var(--color-blue-dark);
  font-weight: 700;

  @media (max-width: ${UI.TABLET}) {
    font-size: 22px;
  }
`

const ExpectationCardItemDescription = styled.div`
  font-size: 20px;
  color: var(--color-blue-dark);
  letter-spacing: 1.6px;
  line-height: 1.5;

  @media (max-width: ${UI.TABLET}) {
    font-size: 18px;
  }
`

const ExpectationCard = ({ title, description }) => (
  <ExpectationCardItem>
    <ExpectationCardItemTitle dangerouslySetInnerHTML={{ __html: title }} />
    <ExpectationCardItemDescription dangerouslySetInnerHTML={{ __html: description }} />
  </ExpectationCardItem>
)

const AboutUsExpectations = () => {
  const { t } = useLangStoreData()

  return (
    <Expectations className='section'>
      <ExpectationsContainer className='container'>
        <ExpectionsTitle>{t('aboutUs__expectionsTitle')}</ExpectionsTitle>
        <ExpectationsCards>
          <ExpectationCard title={t('aboutUs__expectionsCard1Title')} description={t('aboutUs__expectionsCard1Description')} />
          <ExpectationCard title={t('aboutUs__expectionsCard2Title')} description={t('aboutUs__expectionsCard2Description')} />
        </ExpectationsCards>
      </ExpectationsContainer>
    </Expectations>
  )
}

const AboutUsCTA = () => {
  const { t } = useLangStoreData()
  return (
    <PageCTASection
      title={t('aboutUs__ctaTitle')}
      buttonText={t('aboutUs__ctaButton')}
      url={ROUTES.servicesPage}
    />
  )
}