import React from 'react'
import styled from 'styled-components'

import { useLangStoreData } from '../utils/LangStore'

import webImg from '../assets/icons/web.png'
import robotImg from '../assets/icons/robot.png'
import cloudImg from '../assets/icons/cloud.png'
import appsImg from '../assets/icons/apps.png'
import analysisIcon from '../assets/icons/analysis-icon.svg'
import deliveryIcon from '../assets/icons/delivery-icon.svg'
import developmentIcon from '../assets/icons/development-icon.svg'
import validationIcon from '../assets/icons/validation-icon.svg'

import newnoteLogo from '../assets/icons/newnoteLogo.png'
import gscLogo from '../assets/icons/gscLogo.svg'
import guerinLogo from '../assets/icons/geLogo.png'
import isqeLogo from '../assets/icons/isqeLogo.png'
import crcLogo from '../assets/icons/crcLogo.png'
import mrLogo from '../assets/icons/mrLogo.png'

import Page from '../components/Page'
import { ROUTES, UI } from '../constants'

import ImageContainer from '../components/image-container/ImageContainer'

import bannerBigSrc from '../assets/pics/home-page-2.png'
import bannerSmallSrc from '../assets/pics/home-page-2-small.jpg'

const HomePage = () => (
  <Page>
    <HomePageBanner />
    <HomePageServices />
    <HomePageProcess />
    <HomePageClients />
    <HomePageContactUs />
  </Page>
)

export default HomePage

const Banner = styled.section`
  height: 700px;
  overflow: hidden;
  position: relative;
  width: 100%;
`

const Content = styled.div`
  height: 100%;

  display: block;
  margin: 0 auto;
  padding: 20px;
`

const Center = styled.div`
  margin-top: var(--s0);
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  color: white;

  display: block;

  @media (max-width: ${UI.MOBILE}) {
    & {
      gap: 0;
    }
  }
`

const BannerText = styled.h1`
  text-align: center;
  color: white;
  line-height: 1.5;
  font-size: 48px;
  font-weight: bold;
  letter-spacing: 2px;

  @media (max-width: ${UI.TABLET}) {
    & {
      font-size: 26px;
    }
  }
  
  @media (max-width: ${UI.MOBILE}) {
    & {
      text-align: center;
    }
  }

`

const BannerButton = styled.a`
  display: block;
  font-weight: 700;
  width: max-content;
  border: 2px solid var(--color-blue-cta);
  border-radius: var(--radius);
  padding: 20px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 32px;
  cursor: pointer;

  color: var(--color_neutral_lightest);
  background: var(--color-blue-cta);
  -webkit-box-shadow: 0 0em 1em rgb(60 163 213 / 30%);
  box-shadow: 0 0em 1em rgb(60 163 213 / 30%);

  margin: var(--s3) auto 0px;

  &:hover {
    color: var(--color-blue-cta);
    background: none;
  }

  @media (max-width: ${UI.TABLET}) {
    & {
      padding: 15px;
      font-size: 26px;
    }
  }

  @media (max-width: ${UI.MOBILE}) {
    & {
      margin: var(--s3) auto 0 auto;
      -webkit-box-shadow: 0 0em 1em rgb(60 163 213 / 50%);
      box-shadow: 0 0em 1em rgb(60 163 213 / 50%);
    }
  }
`

const HomePageBanner = () => {
  const { t } = useLangStoreData()
  return (
    <>
      <Banner>
        <ImageContainer
          src={bannerBigSrc}
          thumb={bannerSmallSrc}
          alt={'Banner'}
          onIsVisible={() => true}
        />
        <Content className='container'>
          <Center>
            <div>
              <BannerText>{t('homepage__bannerText1')}</BannerText>
              <BannerText style={{ marginTop: '32px' }}>{t('homepage__bannerText2')}</BannerText>
              <BannerButton href={ROUTES.servicesPage}>{t('homepage__bannerCTA')}</BannerButton>
            </div>
          </Center>
        </Content>
      </Banner>
    </>
  )
}

const Services = styled.section`
`

const ServicesIntro = styled.div`
  text-align: center;
  font-size: 28px;
  margin: 0 auto;
`

const ServicesTitle = styled.h2`
  padding-top: var(--s3);
`

const SERVICES_GRID_TWO_COLUMNS_RESOLUTION = '1060px'
const SERVICES_GRID_ONE_COLUMN_RESOLUTION = '522px'

const ServicesContent = styled.div`
  margin-top: var(--s2);

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: center;
  grid-template-rows: 1fr;
  row-gap: 64px;

  @media (max-width: ${SERVICES_GRID_TWO_COLUMNS_RESOLUTION}) {
    & {      
      grid-template-columns: max-content max-content;
      justify-content: center;
      column-gap: 12vw;
    }
  }

  @media (max-width: ${SERVICES_GRID_ONE_COLUMN_RESOLUTION}) {
    & {
      justify-items: center;
      grid-template-columns: 1fr;
    }
  }
`

const ServicesCTA = styled.a`
  margin-top: var(--s2);
`

const HomePageServices = () => {
  const { t } = useLangStoreData()
  return (
    <Services className='container section'>
      <ServicesIntro className='title'><p dangerouslySetInnerHTML={{ __html: t('homepage__companyIntro') }} /></ServicesIntro>
      <ServicesTitle className='section-title'>{t('homepage__servicesTitle')}</ServicesTitle>
      <ServicesContent>
        <ServicesItem text={t('homepage__servicesWeb')} imgSrc={webImg} />
        <ServicesItem text={t('homepage__servicesAutomation')} imgSrc={robotImg} />
        <ServicesItem text={t('homepage__servicesCloud')} imgSrc={cloudImg} />
        <ServicesItem text={t('homepage__servicesMobile')} imgSrc={appsImg} />
      </ServicesContent>
      <ServicesCTA href={ROUTES.servicesPage} className='section-cta-btn'>{t('homepage__servicesCTA')}</ServicesCTA>
    </Services>
  )
}

const ServicesItemStyled = styled.div`
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ServicesItemImage = styled.img`
  width: 100px;
`

const ServicesitemLabel = styled.div`
  text-align: center;
  margin-top: 8px;
  font-weight: 700;
  font-size: 24px;

  @media (max-width: ${UI.MOBILE}) {
    & {
      font-size: 18px;
    }
  }
`

const ServicesItem = ({ text, imgSrc }) => {
  return (
    <ServicesItemStyled>
      <ServicesItemImage src={imgSrc} alt='Services Image' />
      <ServicesitemLabel>{text}</ServicesitemLabel>
    </ServicesItemStyled>
  )
}

const Processes = styled.section`
  background-color: var(--color-blue-dark);
`

const ProcessesSection = styled.section`
  padding: 0px;
`

const ProcessesTitle = styled.h2`
  color: white;
`

const PROCESSES_GRID_TWO_COLUMNS_RESOLUTION = '1240px'
const PROCESSES_GRID_ONE_COLUMN_RESOLUTION = '670px'

const ProcessesContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  justify-items: center;
  row-gap: 64px;
  padding-top: var(--s2);

  @media (max-width: ${PROCESSES_GRID_TWO_COLUMNS_RESOLUTION}) {
    & {
      grid-template-columns: max-content max-content;
      justify-content: center;
      column-gap: 8vw;
    }
  }

  @media (max-width: ${PROCESSES_GRID_ONE_COLUMN_RESOLUTION}) {
    & {
      justify-items: center;
      grid-template-columns: max-content;
    }
  }
`

const ProcessesCTA = styled.a`
  margin-top: var(--s2);
`

const HomePageProcess = () => {
  const { t } = useLangStoreData()
  return (
    <Processes className='section'>
      <ProcessesSection className='container'>
        <ProcessesTitle className='section-title'>{t('homepage__processesTitle')}</ProcessesTitle>
        <ProcessesContent>
          <ProcessesItem title={t('homepage__processesAnalysis')} description={t('homepage__processesAnalysisText')} imgSrc={analysisIcon} />
          <ProcessesItem title={t('homepage__processesDevelopment')} description={t('homepage__processesDevelopmentText')} imgSrc={developmentIcon} />
          <ProcessesItem title={t('homepage__processesValidation')} description={t('homepage__processesValidationText')} imgSrc={validationIcon} />
          <ProcessesItem title={t('homepage__processesDelivery')} description={t('homepage__processesDeliveryText')} imgSrc={deliveryIcon} />
        </ProcessesContent>
      </ProcessesSection>
      <ProcessesCTA href={ROUTES.processPage} className='section-cta-btn'>{t('homepage__processesCTA')}</ProcessesCTA>
    </Processes>
  )
}

const ProcessesItemStyled = styled.div`
  display: flex;
  width: 250px;
  flex-direction: column;  
  align-items: center;
  gap: 15px;
`

const ProcessesItemImage = styled.img`
  width: 100px;
`

const ProcessesItemTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: white;
  letter-spacing: 3px;

  @media (max-width: ${UI.MOBILE}) {
    & {
      font-size: 20px;
      letter-spacing: 1px;   
    }
  }
`

const ProcessesItemDescription = styled.div`
  font-style: 20px;
  color: white;
  text-align: center;

  @media (max-width: ${UI.MOBILE}) {
    & {
      font-size: 18px;
    }
  }
`

const ProcessesItem = ({ title, description, imgSrc }) => {
  return (
    <ProcessesItemStyled>
      <ProcessesItemImage src={imgSrc} alt='Process Image' />
      <ProcessesItemTitle>{title}</ProcessesItemTitle>
      <ProcessesItemDescription>{description}</ProcessesItemDescription>
    </ProcessesItemStyled>
  )
}

const Clients = styled.section`
`

const ClientsContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 64px 128px;
  align-items: center;

  padding-top: var(--s2);

  @media (max-width: ${UI.MOBILE}) {
    flex-direction: column;
  }
`

const ClientsLogo = styled.img`
  max-width: 210px;
  max-height: 100px;

  @media (max-width: ${UI.MOBILE}) {
    max-width: 150px;
  }
`

const HomePageClients = () => {
  const { t } = useLangStoreData()
  return (
    <Clients className='section'>
      <h2 className='section-title'>{t('homepage__clientsTitle')}</h2>
      <ClientsContent>
        <ClientsLogo url='https://salvadorcaetano.pt/' src={gscLogo} alt='GSC Logo' style={{ filter: 'grayscale(1)' }} />
        <ClientsLogo url='https://www.guerin.pt/' src={guerinLogo} alt='Guerin Logo' style={{ filter: 'grayscale(1)' }} />
        <ClientsLogo url='https://www.newnote.pt/' src={newnoteLogo} alt='New Note Logo' style={{ filter: 'brightness(0.5)' }} />

        <ClientsLogo url='https://www.isqe.com/' src={isqeLogo} alt='ISQe Logo' style={{ filter: 'grayscale(1)' }} />
        <ClientsLogo url='https://www.crcrental.pt/' src={crcLogo} alt='CRC Logo' style={{ filter: 'brightness(0.5)' }} />
        <ClientsLogo url='https://www.madeirarent.pt/' src={mrLogo} alt='Madeira Rent Logo' style={{ filter: 'brightness(0.5)' }} />
      </ClientsContent>
    </Clients>
  )
}

const ContactUs = styled.section`
  background-color: var(--color-blue);
`

const ContactUsText = styled.div`
  line-height: 1.5;
  text-align: center;
  font-size: 32px;
  margin: 0 auto;
  color: white;

  @media (max-width: ${UI.TABLET}) {
    & {
      font-size: 26px;
    }
  }
`

const ContactUsCTA = styled.a`
  margin-top: var(--s2);
`

const HomePageContactUs = () => {
  const { t } = useLangStoreData()
  return (
    <ContactUs className='section'>
      <ContactUsText dangerouslySetInnerHTML={{ __html: t('homepage__ContactUsText1') }} />
      <ContactUsText dangerouslySetInnerHTML={{ __html: t('homepage__ContactUsText2') }} />
      <ContactUsCTA className='section-cta-btn' href={ROUTES.contactUsPage}>{t('homepage__ContactUsCTA')}</ContactUsCTA>
    </ContactUs>
  )
}
