export default {
  header__menu__home: 'Home',
  header__menu__aboutUs: 'About Us',
  header__menu__services: 'Solutions',
  header__menu__contactUs: 'Contact Us',
  header__menu__process: 'Process',

  footer__aboutUs: 'From Lisbon, we have been helping our partners achieve their goals through technological innovation since 2011',
  footer__rights: '© Challenge.IT, Lda. All rights reserved',
  footer__navTitle: 'Content',
  footer__resourcesTitle: 'Resources',
  footer__iconsMadeBy: 'Icons made by ',
  footer__iconsFrom: ' from ',


  footer__backgroundsMadeBy: 'Backgrounds made by ',
  footer__backgroundsMadeByDivider: ' created by ',
  footer__backgroundsFrom: ' from ',

  homepage__bannerText1: 'Got a tech challenge in your business?',
  homepage__bannerText2: 'No solutions on the market?',
  homepage__bannerCTA: 'Challenge us',
  homepage__companyIntro: 'We are a company specialized in the development of <b>custom software</b> since 2011',
  homepage__motivationalText:
    "Need a <b>partner</b> to push your business forward?\nLet's make it happen, <b>together</b>.",
  homepage__contactUsButton: 'Contact us',
  homepage__servicesTitle: 'What We Do Best',
  homepage__servicesWeb: 'Web',
  homepage__servicesAutomation: 'Automation',
  homepage__servicesCloud: 'Cloud',
  homepage__servicesMobile: 'Mobile Friendly',
  homepage__servicesCTA: 'Our Solutions »',
  homepage__processesTitle: 'How We Do It',
  homepage__processesCTA: 'Our Process »',
  homepage__processesAnalysis: '1. Analysis',
  homepage__processesAnalysisText: 'Understand the business',
  homepage__processesDevelopment: '2. Development',
  homepage__processesDevelopmentText: 'Develop the solution',
  homepage__processesValidation: '3. Validation',
  homepage__processesValidationText: 'Validate the solution behavior',
  homepage__processesDelivery: '4. Delivery',
  homepage__processesDeliveryText: 'Deploy the solution',
  homepage__clientsTitle: 'Some Of Our Clients',
  homepage__ContactUsText1: 'Need a <b>partner</b> to push your business forward?',
  homepage__ContactUsText2: `Let's make it happen, <b>together</b>!`,
  homepage__ContactUsCTA: 'Contact Us »',

  contactUs__pageText: 'Working from Portugal and developing software for your business anywhere in the world.',

  aboutUS__title: 'We are Challenge IT',
  aboutUS__subTitle: 'From Lisbon, we have been helping our partners achieve their goals through technological innovation since 2011',
  aboutUS__questionPrimary: `Have you been feeling like there's a way to optimize and automate your processes?`,
  aboutUS__questionSecundary1: `Or maybe you feel like there's a clear need on the market, but you don't have an IT partner to make your project come true?`,
  aboutUS__questionSecundary2: `Maybe you even had one partner in the past, but you felt like your partner wasn't able to fully understand your needs or your idea`,
  aboutUS__helpTitle: 'We are here to help you!',
  aboutUS__helpText1: `We can't promise there won't be communication errors between us. We know that no matter how good the intentions and the communicators, when two distinct human beings expose and discuss ideas, there most certainly will be space for doubts and misinterpretation.`,
  aboutUS__helpText2: `What we can promise is that we will do our utmost to reduce the mistakes due to miscommunication, since we strongly believe communication and analysis are key when developing/building a solution. We have been honing and improving our processes of communication and business analysis as a team, since 2011 with our past and current partners.`,
  aboutUS__helpText3: `But there's a limit to what we can achieve alone. In order for this to happen in the best way possible, you will have to engage with us. We are here to help <b>YOU</b>, and for that to happen you'll have to be <b>FULLY COMMITTED</b>.`,
  aboutUs__expectionsTitle: `If you do have a company, and a software challenge then...`,
  aboutUs__expectionsCard1Title: `What can you expect from us?`,
  aboutUs__expectionsCard1Description: `A partner that will do his utmost to build the best solution for your challenges/problems. With that in mind, the best solution isn't necessarily tailor-made software by us, sometimes the best solution might already be out there. But in case you don't know of anything that remotely resembles your needs, or the ones you know of are subpar. Then, we should be able to make it happen, <b>together</b>.`,
  aboutUs__expectionsCard2Title: `What we expect from you`,
  aboutUs__expectionsCard2Description: `A partner that will engage and help us understand and analyse his business. We strongly believe that the best tailor-made software has to be made together, through a cooperative analysis and brainstorm, we expect you to be engaged, as much as us or even more. The <b>more</b>, the <b>better</b>.`,
  aboutUs__ctaTitle: 'Want to know more about our products and services?',
  aboutUs__ctaButton: 'Yes, show me the solutions »',

  services__title: 'Our Solutions',
  services__subTitle: 'Client focused software development through a process based on cooperation, communication, problem solving and acceptance testing',
  services__introTitle: 'Due to our process, continuous improvement mindset and technologies, the solutions we build are robust, scalable and well written.',
  services__introText1: 'Through our services we inspire businesses to accept innovation and discover new opportunities. With a focus on process digitalization and intelligent automation, our solutions automate and simplify jobs related to car rental, financial services, assembly line production, insurance, healthcare, and many more.',
  services__introText2: 'Right now, process automation is one of the most trending technologies and we have experience providing process automation consulting and development services. While having your business needs and goals in mind, we are sure to provide the solution you need in order to push your business forward.',
  services__introText3: 'But we are not exclusively dedicated to process automation solutions. Since our birth, we have provided a varied number of services related to custom software such as, web development, mobile-friendly development, legacy application modernization, systems integration, software audit, data migration services, etc.',
  services__introText4: 'Below, you can find what we currently do, but for the right challenge, we are open to do mostly anything bespoke software related, whether you are an IT company or not.',
  services__productsTitle: 'Products & Services',

  services__productsCustomSoftwareTitle: 'Custom Software Solutions',
  services__productsCustomSoftwareDescription: 'All about the specific needs of your business. Through software, we create the most suitable solution for your business needs and goals. Whether the software will run in your machine, the cloud or a kiosk nearby, our tailor-made solutions are the choice.',

  services__productsProcessWebTitle: 'Web Solutions',
  services__productsProcessWebDescription: `Developed as cross-browser applications, our bespoke web apps seamlessly work for any end-user. You can even provide us your UI design, and we'll make it happen.`,

  services__productsProcessAutomationTitle: 'Process Automation',
  services__productsProcessAutomationDescription: 'Process automation solutions are known to help avoid errors and typos, follow regulatory compliance better, be consistent and efficient in your routine jobs. Spend more time on engaging tasks and activities, while our solutions do the boring or not so engaging part.',

  services__productsIOTTitle: 'Internet of Things',
  services__productsIOTDescription: 'Connect your physical objects into the digital world, through sensors, software and technologies that connect devices and systems. From digitally accessing your "thing" information, through a QR code or an RFID, to remotely accessing and controlling your business operations. Make physically unreachable "things" digitally reachable and trackable.',

  services__productsLegacyModernizationTitle: 'Legacy Solutions Modernization',
  services__productsLegacyModernizationDescription: 'By identifying your challenges and tech gaps, we create a suitable modernization strategy. Through application decommissioning, implementation of new systems, data migration and software upgrades, our clients will have increased flexibility and improved operational efficiency.',

  services__productsSoftwareAuditTitle: 'Software Audit Review',
  services__productsSoftwareAuditDescription: 'Our team will verify the quality, risks, unexplored opportunities and compliance with your business requirements. We focus on software features and source code evaluation. After the analysis, we present the strengths and the weaknesses, as well as suggestions on how to improve.',

  services__productsESDTitle: 'Enterprise Software Solutions',
  services__productsESDDescription: 'We create a custom software solution for your company, based on a thoroughly understanding your business. In the process our team might suggest to modernize legacy systems and integrate new apps. By telling us about your business challenges, we research your business in detail and offer the most suitable solutions.',

  services__productsSystemIntegrationTitle: 'System Integration',
  services__productsSystemIntegrationDescription: `Want to adopt an existing software solution into your company? Or maybe you need your solution to integrate with a third-party API? Our team will analyse its viability and verify your business and software current state, in order to integrate the solution seamlessly. When possible, your ongoing processes won't be affected.`,

  services__productsSPDTitle: 'Software Product Development',
  services__productsSPDDescription: 'Create a product from scratch with us. With our exclusive development or with help from other developers, our team will help you plan and build the software product that is tailored to your reality. By involving you in the decision-making process and user acceptance testing phases, we expect many common pains and risks to be avoided.',

  services__ctaTitle: 'Want to know how it all comes to life?',
  services__ctaButton: 'Show me the process »',

  process__title: 'Our Process',
  process__subTitle: 'Cooperation, communication, problem solving and testing are the keys that make our process work',

  process__intro: 'Based on four distinct phases that complement each other, our process covers most of our services from start to finish, resulting in robust solutions suitable for your needs.',

  process__analysisTitle: 'Analysis',
  process__analysisText1: 'To start things off, our team will gather all functional and non-functional requirements, conduct respective analyses including integration impact analysis, and identify all opportunities and values your company will be able to get from our services.',
  process__analysisText2: 'This is the requirement gathering phase, it is done in a cooperative fashion and is of utmost importance to be done thoroughly, in order to ensure a consistent and reliable plan of action.',

  process__developmentTitle: 'Development',
  process__developmentText1: 'As a result of the previous analysis phase, a specification of the solution was created. When the solution has new software, this is the phase where a first version of the software is created.',
  process__developmentText2: 'Some of our development services include API integration, API design and architecture, integration with third party systems, software maintenance and consulting. ',

  process__validationTitle: 'Validation',
  process__validationText1: 'All our software undergo a series of tests, including stress testing, according to the expected capacity. Since we strongly believe that user acceptance testing is essential, you will be able to test the product and give us your feedback. ',
  process__validationText2: 'After the analysis phase, this is the second most important step. Because based on your feedback we have the chance to fix any bugs, develop any additional functionality, or make any tiny changes you might need.',

  process__deliveryTitle: 'Delivery',
  process__deliveryText1: 'On the delivery phase, we check the deployment environment for any issues. If the environment is your responsibility, we will be available to make some adjustments to the solution or provide you recommendations in case any issues occur. ',
  process__deliveryText2: 'Our experts will schedule the deployment date and help you accordingly. We can also develop and provide you with documentation if needed. Generally, the plan for this phase is done in the analysis phase, so we expect you to consider most of the details at that time.',

  process__wrapUpText1: 'Starting with analysis, following with development, then validation and ending with the delivery, our general process normally takes a sequential and cyclic approach.',
  process__wrapUpText2: 'As a rule of thumb, we aim for small and incremental cycles of this process, in order to have real feedback as soon as possible, but also to add value (when possible) through small, but impactful deliverables.',
  process__wrapUpText3: 'The amount of cycles of our process your project will take in order to be complete, will depend mainly on how complex and ambitious the project is.',
  process__wrapUpText4: 'Below you can find a small breakdown of the factors we believe that impact both the duration and the costs of a project.',

  process__durationTitle: 'Duration',
  process__durationText: 'Many factors can affect the duration of our services. Below we list the ones we consider the most impactful:',
  process__durationFactor1: 'Your availability',
  process__durationFactor2: 'Third party integrations',
  process__durationFactor3: 'Project requirements',
  process__durationFactor4: 'Expected deadlines',

  process__costTitle: 'Costs',
  process__costText: 'Like the duration, many factors can equally impact the cost of a service. We usually take into account the following points:',
  process__costFactor1: 'Your availability',
  process__costFactor2: 'Project scope and complexity',
  process__costFactor3: 'Project completion urgency',
  process__costFactor4: 'Chosen technology',

  process__ctaTitle: 'Now that you know us, our solutions and our process, what are you waiting for?',
  process__ctaButton: 'Contact us »',
  
}
