import styled from 'styled-components'

import { UI } from '../constants'
import ImageContainer from './image-container/ImageContainer'

const BannerStyled = styled.section`
  text-align: center;
  height: 700px;

  @media (max-width: ${UI.MOBILE}) {
    & {
      height: 600px;
    }
  }
`

const Center = styled.div`
  position: relative;
  top: 50%;
  padding: 0 10%;
  transform: translateY(-50%);
  color: white;
`

const BannerTitle = styled.h1`
  width: 100%;
  text-align: center;
  margin: 0 auto;
  font-size: 48px;

  @media (max-width: ${UI.MOBILE}) {
    & {
      font-size: 32px;
    }
  }
`

const BannerSubTitle = styled.p`
  max-width: 540px;
  text-align: center;
  margin: 0 auto;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 2px;
  line-height: 1.5;
  margin-top: var(--s1);

  @media (max-width: ${UI.TABLET}) {
    & {
      margin-top: 16px;
    }
  }
`

const BannerContainer = styled.div`
  height: 700px;
  overflow: hidden;
  position: relative;
  width: 100%;
`

function Banner({ title, subTitle, imgSrc, smallImgSrc }) {

  return (
    <>
      <BannerContainer>
        <ImageContainer
          src={imgSrc}
          thumb={smallImgSrc}
          alt={'Banner'}
          onIsVisible={() => true}
        />
        <BannerStyled className='section'>
          <Center>
            <BannerTitle>{title}</BannerTitle>
            <BannerSubTitle>{subTitle}</BannerSubTitle>
          </Center>
        </BannerStyled>
      </BannerContainer>
    </>
  )
}

export default Banner
