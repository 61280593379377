import React from 'react'

import en from '../assets/locales/en.js'
import pt from '../assets/locales/pt.js'
import { UTILS } from '../constants'

import computeDefaultLang from './computeDefaultLang'

const TRANSLATIONS = { en: en, pt: pt }
const SUPPORTED_LANGS = Object.keys(TRANSLATIONS)
const LOCAL_STORAGE_LANG_KEY = 'lang'
const DEFAULT_LANG = localStorage.getItem('en' || LOCAL_STORAGE_LANG_KEY) || computeDefaultLang()
const throwInvalidStoreAccessError = () => {
  throw UTILS.InvalidStoreAccessError('LangStore', 'LangStoreProvider')
}

/****************************************************************/
/***************************** DATA *****************************/

/** @type Readonly<{ lang: string, supportedLangs:string[], t: function(string):string }> */
const noData = Object.freeze({ lang: '', supportedLangs: [], t: _key => throwInvalidStoreAccessError() })

const LangStoreDataContext = React.createContext(noData)
LangStoreDataContext.displayName = 'LangStoreDataContext'

function createData(privateState) {
  let translations = TRANSLATIONS[privateState] || TRANSLATIONS[DEFAULT_LANG]
  return { lang: privateState, supportedLangs: SUPPORTED_LANGS, t: key => translations[key] }
}

/** A hook that gives access to the languages and translations in the LangStore. */
export function useLangStoreData() {
  return React.useContext(LangStoreDataContext)
}

/****************************************************************/
/*************************** ACTIONS ****************************/

/** @type Readonly<{ setLang: function(string):void }> */
const noActions = Object.freeze({ setLang: _lang => throwInvalidStoreAccessError() })

const LangStoreActionsContext = React.createContext(noActions)
LangStoreActionsContext.displayName = 'LangStoreActionsContext'

function createActions(setPrivateState) {
  const setLang = lang => {
    localStorage.setItem(LOCAL_STORAGE_LANG_KEY, lang)
    setPrivateState(lang)
  }
  return { setLang }
}

/** A hook that gives access to the actions of the LangStore. */
export function useLangStoreActions() {
  return React.useContext(LangStoreActionsContext)
}

/****************************************************************/
/*************************** PROVIDER ***************************/

/**
 * A provider of the supported languages, selected language and respective translations, and language-related actions.
 *
 * To access the data or the actions, make sure your React component is wrapped by a {@link LangStoreProvider} upper in
 * the render-tree and invoke the hooks {@link #useLangStoreData} or {@link #useLangStoreActions}, respectively.
 */
export const LangStoreProvider = props => {
  const [privateState, setPrivateState] = React.useState(DEFAULT_LANG)
  const actions = React.useMemo(() => createActions(setPrivateState), [setPrivateState])
  const data = React.useMemo(() => createData(privateState), [privateState])
  return (
    <LangStoreActionsContext.Provider value={actions}>
      <LangStoreDataContext.Provider value={data}>{props.children}</LangStoreDataContext.Provider>
    </LangStoreActionsContext.Provider>
  )
}
