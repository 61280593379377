import React from 'react'
import styled from 'styled-components'

import externalLinkIcon from '../assets/icons/external.png'

import { CSS_PROPERTIES, EXTERNAL_LINKS } from './ie-constants'
import { useLangStoreIEData } from './LangStoreIE'


const Section = styled.section`
  margin: 0 auto;
  padding: ${CSS_PROPERTIES.LENGTHS.s0} 32px;
  width: 100%;
  
  box-shadow: inset 0 0 8px 10px ${CSS_PROPERTIES.COLORS.blue};
  
  & a {
    color: ${CSS_PROPERTIES.COLORS.blue}
  }
`

const ExternalLinkIcon = styled.img`
  height: 15px;
  padding: 0 5px;
`

const ExternalLink = ({ href, title, children }) => (
  <a href={href} title={title} target="_blank" rel="noopener">
    {children}
  </a>
)

const Paragraph = styled.p`
  color: ${CSS_PROPERTIES.COLORS.blue_dark};
  text-align: center;
  font-size: 14px;
  margin: 0 auto;
  max-width: 50ch;
  min-width: 80%;

  & + & {
    margin-top: 0.5rem;
  }
`

const DisclaimerIE = () => {
  const { t } = useLangStoreIEData()
  return (
    <Section>
      <Paragraph>
        {t('disclaimer__text1')}
        <ExternalLink href={EXTERNAL_LINKS.MICROSOFT_SUPPORT} title="Microsoft Support">
          <ExternalLinkIcon src={externalLinkIcon} alt="Microsoft Support page" />
        </ExternalLink>
      </Paragraph>
      <Paragraph>
        {t('disclaimer__text2')}
        <br />
        <ExternalLink href={'microsoft-edge:' + window.location} title="Open in Microsoft Edge">
          Microsoft Edge
        </ExternalLink>
        {', '}
        <ExternalLink href={EXTERNAL_LINKS.GOOGLE_CHROME_DOWNLOAD} title="Google Chrome">
          Google Chrome
        </ExternalLink>
        {' or '}
        <ExternalLink href={EXTERNAL_LINKS.MOZILLA_FIREFOX_DOWNLOAD} title="Mozilla Firefox">
          Mozilla Firefox
        </ExternalLink>
      </Paragraph>
    </Section>
  )
}

export default DisclaimerIE
