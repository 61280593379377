import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as LinkedinSvg } from '../assets/icons/svg-linkedin.svg'
import logoSrc from '../assets/logoHeader435x383.png'
import externalIcon from '../assets/icons/external.png'

import { ROUTES, UI } from '../constants'
import { useLangStoreActions, useLangStoreData } from '../utils/LangStore'
// import LanguagePicker from '../widgets/LanguagePicker'
import { ExternalLinkIconButton } from '../widgets/LinkButton'
import Menu, { MenuMobile } from './Menu'


const HeaderContainer = styled.div`
  width: 100%;

  padding: 10px 20px;
  position: fixed;
  top:0;
  z-index: 999;
`

const Header = styled.header`
  color: var(--color_text_overBgHeader);

  width: 100%;
  height: 80px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 900px) {

    .hamburger {
      display: flex;
    }

    .header-menu {
      display: none;
    }

    .header-menu-mobile--open {
      display: block;
    }

  }

`

const Logo = styled.img`
  height: 50px;
  image-rendering: -webkit-optimize-contrast;
  position: relative;
  z-index: 3;
`

const Main = styled.main`
  width: 100%;
`

const Page = ({ children }) => {
  const { lang, supportedLangs, t } = useLangStoreData()
  const { setLang } = useLangStoreActions()

  const [scrolled, setScrolled] = useState(false)
  const [hamburgerOpen, setHamburgerOpen] = useState(false)

  useEffect(() => {
    window.onscroll = function () {
      setScrolled(document.documentElement.scrollTop > 10)
    }
    setScrolled(document.documentElement.scrollTop > 10)
    return () => {
      window.onscroll = function () { }
    }
  }, [setScrolled])

  function handleHamburgerClick() {
    setHamburgerOpen(v => !v)
  }

  return (
    <>
      <HeaderContainer style={scrolled ? { backgroundColor: 'rgba(0,0,0,.85)' } : {}}>
        <Header className='container'>
          {/*<LanguagePicker supportedLangs={supportedLangs} selectedLang={lang} setSelectedLang={setLang} />*/}
          <Link to={ROUTES.homePage}>
            <Logo src={logoSrc} alt="Challenge IT Logo" />
          </Link>
          <Menu />
          <MenuMobile isOpen={hamburgerOpen} />
          <Hamburger handleHamburgerClick={handleHamburgerClick} />
        </Header>
      </HeaderContainer>
      <Main>{children}</Main>
      <Footer />
    </>
  )
}

export default Page

const HamburgerContainer = styled.div`
  display: none;
  width: 25px;
  flex-direction: column;
  gap: 3px;
  cursor: pointer;
  position: relative;
  z-index: 3;
`

const HamburgerLines = styled.div`
  background: white;
  border-radius: 5px;
  width: 100%;
  height: 4px;
`

const Hamburger = ({ handleHamburgerClick }) => {
  return (
    <HamburgerContainer className='hamburger' onClick={handleHamburgerClick}>
      <HamburgerLines />
      <HamburgerLines />
      <HamburgerLines />
    </HamburgerContainer>
  )
}

const FooterSection = styled.footer`
  padding: 32px;
  background-color: black;
  color: #a4a4a4;
`

const FooterContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  margin-bottom: 25px;
  justify-content: space-between;

  @media (max-width: ${UI.TABLET}) {
    flex-direction: column;
  }
`

const FooterLogo = styled.img`
  height: 50px;
  margin-bottom: 10px;
`

const FooterDescription = styled.p`
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  width: 250px;
`

const FooterNavTitle = styled.div``

const FooterNav = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`
const FooterNavItem = styled.a``

const FooterResourcesTitle = styled.div``

const FooterResources = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const FooterRights = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const FooterLine = styled.div`
  width: 100%;
  height: 2px;
  background-color: gray;
`

const FooterIcons = styled.div`
  display: flex;
`

const FOOTER_TITLE = {
  color: 'white',
  fontWeight: '700',
  fontSize: '20px',
  marginBottom: '20px',
}

const FooterResourcesItemElem = styled.div`
  font-size: 14px;
  position: relative;
  cursor: pointer;

  & a {
    color: gray;
  }

  &:hover {
    .resources-modal {
      display: flex;
    }
  }
`

const FooterResourcesLink = styled.img`
  height: 15px;
`

const FooterResourcesModal = styled.div`
  display: none;

  background-color: white;
  border-radius: var(--radius);
  box-shadow: 0px 4px 10px rgba(0,0,0,.5);

  min-width: 300px;

  padding: 20px;
  position: absolute;
  top: -100%;
  right: 0;
  transform: translateY(-100%);
`

const FOOTER_ICONS_ATTRIBUTES = [
  { src: 'https://www.flaticon.com/authors/srip', text: 'srip' },
  { src: 'https://www.freepik.com', text: 'Freepik' },
  { src: 'https://www.flaticon.com/authors/eucalyp', text: 'Eucalyp' },
  { src: 'https://www.flaticon.com/authors/Uniconlabs', text: 'Uniconlabs' },
  { src: 'https://www.flaticon.com/authors/icon-home', text: 'Icon home' },
  { src: 'https://www.flaticon.com/authors/flat-icons', text: 'Flat Icons' },
  { src: 'https://www.flaticon.com/authors/earthz-stocker', text: 'Earthz Stocker' },
  { src: 'https://www.flaticon.com/authors/surang', text: 'surang' },
]

const FOOTER_BACKGROUNDS_ATTRIBUTES = [
  { src: 'https://www.freepik.com/vectors/background', resourceTypeText: 'Background vector', authorText: 'GarryKillian', fromText: 'www.freepik.com' }
]

const Footer = () => {
  const { t } = useLangStoreData()

  return (
    <FooterSection>
      <div className='container'>
        <FooterContent>
          <div>
            <FooterLogo src={logoSrc} alt='Challenge IT Logo' />
            <FooterDescription>{t('footer__aboutUs')}</FooterDescription>
          </div>
          <div>
            <FooterNavTitle style={FOOTER_TITLE}>{t('footer__navTitle')}</FooterNavTitle>
            <FooterNav>
              <FooterNavItem href={ROUTES.homePage}>{t('header__menu__home')}</FooterNavItem>
              <FooterNavItem href={ROUTES.aboutUsPage}>{t('header__menu__aboutUs')}</FooterNavItem>
              <FooterNavItem href={ROUTES.servicesPage}>{t('header__menu__services')}</FooterNavItem>
              <FooterNavItem href={ROUTES.contactUsPage}>{t('header__menu__contactUs')}</FooterNavItem>
            </FooterNav>
          </div>
          <div>
            <FooterResourcesTitle style={FOOTER_TITLE}>{t('footer__resourcesTitle')}</FooterResourcesTitle>
            <FooterResourcesItemElem>
              {t('footer__iconsMadeBy')}
              <FooterResourcesLink src={externalIcon} alt='External Icon' />
              <FooterResourcesModal className='resources-modal'>
                <FooterResources>
                  {FOOTER_ICONS_ATTRIBUTES.map(icon =>
                    <FooterResourcesItemElem key={icon.src}>{t('footer__iconsMadeBy')}<a href={icon.src} title={icon.text} target="_blank">{icon.text}</a>{t('footer__iconsFrom')}<a href="https://www.flaticon.com/" target="_blank" title="Flaticon">www.flaticon.com</a></FooterResourcesItemElem>
                  )}
                </FooterResources>
              </FooterResourcesModal>
            </FooterResourcesItemElem>
            <FooterResourcesItemElem style={{ marginTop: '15px' }}>
              {t('footer__backgroundsMadeBy')}
              <FooterResourcesLink src={externalIcon} alt='External Icon' />
              <FooterResourcesModal className='resources-modal'>
                <FooterResources>
                  {FOOTER_BACKGROUNDS_ATTRIBUTES.map(background =>
                    <FooterResourcesItemElem key={background.src}>{background.resourceTypeText}{t('footer__backgroundsMadeByDivider')}{background.authorText}<a href={background.src} title={background.authorText} target="_blank">{background.text}</a>{t('footer__backgroundsFrom')}<a href={background.src} target="_blank" title="from">{background.fromText}</a></FooterResourcesItemElem>
                  )}
                </FooterResources>
              </FooterResourcesModal>
            </FooterResourcesItemElem>
            <a href="#" id="open_preferences_center" style={{ fontSize: '14px', display: 'block', marginTop: '15px' }}>Update cookies preferences</a>
          </div>
        </FooterContent>
        <div>
          <FooterLine />
          <FooterRights>
            <p>{t('footer__rights')}</p>
            <FooterIcons>
              <ExternalLinkIconButton href="https://www.linkedin.com/company/challenge-it-lda" Icon={LinkedinSvg} fill='gray' />
            </FooterIcons>
          </FooterRights>
        </div>
      </div>
    </FooterSection>
  )
}
