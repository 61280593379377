function computeDefaultLang() {
  /*
  const browserLanguages = navigator.languages

  if (browserLanguages.includes('pt')) {
    const indexOfPT = browserLanguages.indexOf('pt')
    const indexOfEN = browserLanguages.indexOf('en')
    return indexOfEN < 0 || indexOfEN > indexOfPT ? 'pt' : 'en'
  }
  */

  return 'en'
}

export default computeDefaultLang
