import React from 'react'
import styled from 'styled-components'

import { CSS_PROPERTIES, UI } from './ie-constants'
import PageIE from './PageIE'
import { useLangStoreIEData } from './LangStoreIE'


const HomePageIE = () => (
  <PageIE>
    <Banner />
    <CompanyIntro />
    <ContactUs />
  </PageIE>
)

export default HomePageIE


const StyledBanner = styled.section`
  background: black;
  height: calc(700px - ${CSS_PROPERTIES.LENGTHS.header_height});
  overflow: hidden;
  position: relative;
  width: 100%;

  display: block;
  margin: 0 auto;
  padding: 20px;
`

const Center = styled.div`
  margin-top: ${CSS_PROPERTIES.LENGTHS.s0};
  position: relative;
  top: calc(50% - ${CSS_PROPERTIES.LENGTHS.header_height});
  transform: translateY(-50%);
  color: white;

  display: block;
`

const BannerText = styled.p`
  text-align: center;
  color: ${CSS_PROPERTIES.COLORS.gray};
  line-height: 1.5;
  font-size: 48px;
  font-weight: bold;
  letter-spacing: 2px;

  @media (max-width: ${UI.MAX_WIDTH_TABLET}) {
    font-size: 26px;
  }
`

const BannerCTA = styled(BannerText)`
  color: white;
  margin-top: 84px;
  text-transform: uppercase;
`

const Banner = () => {
  const { t } = useLangStoreIEData()
  return (
    <StyledBanner>
      <Center>
        <BannerText>{t('banner__text1')}</BannerText>
        <BannerText style={{ marginTop: '32px' }}>{t('banner__text2')}</BannerText>
        <BannerCTA>{t('banner__CTA')}</BannerCTA>
      </Center>
    </StyledBanner>
  )
}

const HomePageSection = styled.section`
  width: 100%;
  text-align: center;
  font-size: 28px;
  margin: 0 auto;

  padding: ${CSS_PROPERTIES.LENGTHS.s3} 20px;

  display: flex;
  flex-direction: column;
  align-items: center;

  & p {
    max-width: 50ch;
    width: 100%;
  }

  & b {
    white-space: nowrap;
  }

  @media (max-width: ${UI.MAX_WIDTH_TABLET}) {
    font-size: 24px;
  }
`

const StyledCompanyIntro = styled(HomePageSection)`
  background: white;
  color: ${CSS_PROPERTIES.COLORS.blue_dark};
`

const CompanyIntro = () => {
  const { t } = useLangStoreIEData()
  return (
    <StyledCompanyIntro>
      <p dangerouslySetInnerHTML={{ __html: t('companyIntro') }} />
    </StyledCompanyIntro>
  )
}

const StyledContactUs = styled(HomePageSection)`
  background-color: ${CSS_PROPERTIES.COLORS.blue};
  color: white;
`

const Email = styled.p`
  margin-top: ${CSS_PROPERTIES.LENGTHS.s0};

  &::after {
    content: "info@challenge.pt";
    font-weight: bold;
    text-decoration: underline;
  }
`

const ContactUs = () => {
  const { t } = useLangStoreIEData()
  return (
    <StyledContactUs>
      <p dangerouslySetInnerHTML={{ __html: t('contactUs_text1') }} />
      <p dangerouslySetInnerHTML={{ __html: t('contactUs_text2') }} />
      <Email />
    </StyledContactUs>
  )
}