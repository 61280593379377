import React from 'react'
import styled from 'styled-components'

import bannerBigSrc from '../assets/pics/contact-us.jpg'
import bannerSmallSrc from '../assets/pics/contact-us-small.png'

import { useLangStoreData } from '../utils/LangStore'

import Page from '../components/Page'

const Banner = styled.div`
  text-align: center;
  height: 100vh;

  background: black;
  background: linear-gradient(
    rgba(0, 0, 0, 0.5), 
    rgba(0, 0, 0, 0.5)
  ), url(${bannerBigSrc}), url(${bannerSmallSrc});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 30%;
`

const Center = styled.div`
  position: relative;
  top: 50%;
  padding: 0 10%;
  transform: translateY(-50%);
  color: white;

  & p.email {
    font-size: 1.75rem;
    font-weight: bold;
    line-height: normal;
    text-decoration: underline 2px solid var(--color_primary);
    padding-top: 1.5rem;
  }

  & p.email::after {
    content: "info@challenge.pt";
  }
`

const ContactUsPage = () => {
  const { t } = useLangStoreData()
  return (
    <Page>
      <Banner>
        <Center>
          <p>{t('contactUs__pageText')}</p>
          <p className="email" />
        </Center>
      </Banner>
    </Page>
  )
}

export default ContactUsPage
