export default {
  header__menu__home: 'Home',
  header__menu__aboutUs: 'Sobre',
  header__menu__services: 'Serviços',
  header__menu__contactUs: 'Contactos',
  header__menu__process: 'Processo',

  footer__aboutUs: 'From Lisbon, we have been helping our partners achieve their goals through technological innovation since 2011',
  footer__rights: '© Challenge.IT, Lda. Todos os direitos reservados',
  footer__navTitle: 'Conteúdo',
  footer__resourcesTitle: 'Recursos',
  footer__iconsMadeBy: 'Ícones criados por ',
  footer__iconsFrom: ' de ',

  homepage__bannerText: 'A sua empresa tem um <b>desafio tecnológico</b>?\n' + 'Não encontra soluções no mercado?',
  homepage__bannerCTA: 'Desafie-nos.',
  homepage__companyIntro: 'Somos uma empresa especialista em desenvolvimento de <b>software à medida</b> desde 2011.',
  homepage__motivationalText:
    'Precisa de um <b>parceiro</b> para dar o salto no seu negócio?\nVamos a isso, <b>juntos</b>.',
  homepage__contactUsButton: 'Contacte-nos',
  homepage__bannerText1: 'Got a tech challenge in your business?',
  homepage__bannerText2: 'No solutions on the market?',
  homepage__servicesTitle: 'What We Do Best',
  homepage__servicesWeb: 'Web',
  homepage__servicesAutomation: 'Automation',
  homepage__servicesCloud: 'Cloud',
  homepage__servicesMobile: 'Mobile',
  homepage__servicesCTA: 'Our Services »',
  homepage__processesTitle: 'How We Do It',
  homepage__processesCTA: 'Our Process »',
  homepage__processesAnalysis: '1. Analysis',
  homepage__processesAnalysisText: 'Understand the business',
  homepage__processesDevelopment: '2. Development',
  homepage__processesDevelopmentText: 'Develop the solution',
  homepage__processesValidation: '3. Validation',
  homepage__processesValidationText: 'Validate the solution behavior',
  homepage__processesDelivery: '4. Delivery',
  homepage__processesDeliveryText: 'Deploy the solution',
  homepage__clientsTitle: 'Some Of Our Clients',
  homepage__ContactUsText1: 'Need a <b>partner</b> to push your business forward?',
  homepage__ContactUsText2: `Let's make it happen, <b>together</b>`,
  homepage__ContactUsCTA: 'Contact Us »',

  contactUs__pageText: 'Desenvolvemos software para a sua empresa, de Portugal para qualquer parte do mundo.',

  aboutUS__title: 'We are Challenge IT',
  aboutUS__subTitle: 'From Lisbon, we have been helping our partners achieve their goals through technological innovation since 2011',
  aboutUS__questionPrimary: `Have you been feeling like there's a way to optimize and automate your processes?`,
  aboutUS__questionSecundary1: `Or maybe you feel like there's a clear need on the market, but you don't have an IT partner to make your project come true?`,
  aboutUS__questionSecundary2: `Maybe you even had one partner in the past, but you felt like your partner wasn't able to fully understand your needs or your idea`,
  aboutUS__helpTitle: 'We are here to help you!',
  aboutUS__helpText1: `We can't promise there won't be communication errors between us. We know that no matter how good the intentions and the communicators, when two distinct human beings expose and discuss ideas, there most certainly will be space for doubts and misinterpretation`,
  aboutUS__helpText2: `What we can promise is that we will do our utmost to reduce the mistakes due to miscommunication, since we strongly believe communication and analysis are key when developing/building a solution. We have been honing and improving our processes of communication and business analysis as a team, since 2011 with our past and current partners`,
  aboutUS__helpText3: `But there's a limit to what we can achieve alone. In order for this to happen in the best way possible, you will have to engage with us. You should remember at all times that we are here to help YOU, and we can only help someone that WANTS to be helped`,
  aboutUs__expectionsTitle: `If you do have a company, and a software challenge then...`,
  aboutUs__expectionsCard1Title: `What can you expect from us?`,
  aboutUs__expectionsCard1Description: `A partner that will do his utmost to build the best solution for your challenges/problems. With that in mind, the best solution isn't necessarily tailor-made software by us, sometimes the best solution might already be out there. But in case you don't know of anything that remotely resembles your needs, or the ones you know of are subpar. Then, we should be able to make it happen, together.`,
  aboutUs__expectionsCard2Title: `What we do expect from you?`,
  aboutUs__expectionsCard2Description: `A partner that will engage and help us understand and analyze his business. We strongly believe that the best tailor-made software has to be made together, through a cooperative analysis and brainstorm, we expect you to be engaged, as much as us or even more. The more, the better`,
  aboutUs__ctaTitle: 'Want to know more about our services?',
  aboutUs__ctaButton: 'Show me your services »',
  
  services__title: 'Our Services',
  services__subTitle: 'Client focused software development through a process based on cooperation, communication, problem solving and acceptance testing',
  services__introTitle: 'Due to our process, continuous improvement mindset and technologies, the solutions we build are robust, scalable and well written.',
  services__introText1: 'Through our services we inspire businesses to accept innovation and discover new opportunities. With a focus on intelligent automation our solutions automate and simplify jobs related to financial services, insurance, healthcare, retail, and many more',
  services__introText2: 'Right now, it is one of the most trending technologies and we have experience providing RPA consulting and development services. While having your business needs and goals in mind, we are sure to provide the solution you need in order to push your business forward',
  services__introText3: 'But we are not exclusively dedicated to RPA solutions. Since our birth, we have provided a varied number of services related to custom software such as, web development, mobile development, legacy application modernization, systems integration, software audit, data migration services, etc',
  services__introText4: 'Below, you can find what our specialties currently are, but for the right challenge, we are open to do mostly anything bespoke software related, whether you are a IT company or not',
  services__productsTitle: 'Products & Services',
  
  services__productsCustomSoftwareTitle: 'Custom software',
  services__productsCustomSoftwareDescription: 'All about the specific needs of your business. Through software, we create the most suitable solution for your business needs and goals. Whether the software will run in your machine, the cloud or a kiosk nearby, our tailor-made solutions are the choice',

  services__productsProcessWebTitle: 'Web applications',
  services__productsProcessWebDescription: 'Developed as cross-browser applications, our bespoke web apps seamlessly work for any end-user. Provide us the UI design, and we make it happen',
  
  services__productsProcessAutomationTitle: 'Process Automation',
  services__productsProcessAutomationDescription: 'RPA solutions are known to help avoid errors and typos, follow regulatory compliance better, be consistent and efficient in your routine jobs. Spend more time on engaging tasks and activities, while our solutions do the boring/not so engaging part',

  services__ctaTitle: 'Want to know how it all comes to life?',
  services__ctaButton: 'Show me your process »',

}
