import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import AboutUsPage from './pages/AboutUsPage'
import ContactUsPage from './pages/ContactUsPage'
import HomePage from './pages/HomePage'
import ServicesPage from './pages/ServicesPage'
import ProcessPage from './pages/ProcessPage'

import { ROUTES } from './constants'
import { LangStoreProvider } from './utils/LangStore'

function App() {
  return (
    <LangStoreProvider>
      <BrowserRouter>
        <Routes>
          <Route path={ROUTES.homePage} element={<HomePage />} />
          <Route path={ROUTES.aboutUsPage} element={<AboutUsPage />} />
          <Route path={ROUTES.servicesPage} element={<ServicesPage />} />
          <Route path={ROUTES.contactUsPage} element={<ContactUsPage />} />
          <Route path={ROUTES.processPage} element={<ProcessPage />} />
        </Routes>
      </BrowserRouter>
    </LangStoreProvider>
  )
}

export default App
