import React from 'react'

const translations_en_ie = {

  disclaimer__text1: 'Our website does not support Internet Explorer.',
  disclaimer__text2: 'Please, you use a modern web browser such as',

  banner__text1: 'Got a tech challenge in your business?',
  banner__text2: 'No solutions on the market?',
  banner__CTA: 'Challenge us',

  companyIntro: 'We are a company specialized in the development of <b>custom software</b> since 2011',

  contactUs_text1: 'Need a <b>partner</b> to push your business forward?',
  contactUs_text2: "Let's make it happen, <b>together</b>!",

  footer__aboutUs: 'From Lisbon, we have been helping our partners achieve their goals through technological innovation since 2011',
  footer__rights: '© Challenge.IT, Lda. All rights reserved',
}

/**
 * @param {string} i18nKey
 * @return {string}
 */
const t_en = i18nKey => translations_en_ie[i18nKey]

export function useLangStoreIEData() {
  const [result] = React.useState({ lang: 'en', supportedLangs: ['en'], t: t_en })
  return result
}
