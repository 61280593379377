const RATIO = 1.61803398875
const S0 = 1.5

export const CSS_PROPERTIES = {
  COLORS: {
    blue: '#0F4C75',
    blue_dark: '#1B262C',
    gray: '#a4a4a4'
  },
  LENGTHS: {
    header_height: '80px',
    s0: S0 + 'rem',
    s3: S0 * RATIO * RATIO * RATIO + 'rem'
  }
}

export const EXTERNAL_LINKS = {
  CHALLENGE_LINKEDIN: 'https://www.linkedin.com/company/challenge-it-lda',
  GOOGLE_CHROME_DOWNLOAD: 'https://google.com/chrome',
  MICROSOFT_SUPPORT:
    'https://support.microsoft.com/en-us/topic/this-website-works-better-in-microsoft-edge-160fa918-d581-4932-9e4e-1075c4713595',
  MOZILLA_FIREFOX_DOWNLOAD: 'https://mozilla.org/firefox/'
}

export const UI = {
  MAX_WIDTH_MOBILE: '480px',
  MAX_WIDTH_TABLET: '768px'
}