import styled from 'styled-components'

import { UI } from '../constants'


const CTASection = styled.section`
  background-color: var(--color-blue);
  color: white;
`

const CTAContainer = styled.div``

const CTATitle = styled.h2`
  margin: 0 auto var(--s2) auto;
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  max-width: 50ch;

  @media (max-width: ${UI.TABLET}) {
    font-size: 26px;
  }
`

const PageCTASection = ({ title, buttonText, url }) => {
  return (
    <CTASection className='section'>
      <CTAContainer className='container'>
        <CTATitle>{title}</CTATitle>
        <a className='section-cta-btn' href={url}>{buttonText}</a>
      </CTAContainer>
    </CTASection>
  )
}

export default PageCTASection
