import React from 'react'
import styled from 'styled-components'

import { ReactComponent as LinkedinSvg } from '../assets/icons/svg-linkedin.svg'
import logoSrc from '../assets/logoHeader435x383.png'

import { CSS_PROPERTIES, EXTERNAL_LINKS } from './ie-constants'
import FooterIconLink from './FooterIconLink'
import { useLangStoreIEData } from './LangStoreIE'
import DisclaimerIE from './DisclaimerIE'


const Header = styled.header`
  width: 100%;
  background: black;
  height: calc(10px + ${CSS_PROPERTIES.LENGTHS.header_height} + 10px);
  padding: 10px 20px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Logo = styled.img`
  height: 50px;
  image-rendering: -webkit-optimize-contrast;
`

const Main = styled.main`
  width: 100%;
`

const Page = ({ children }) => (
  <>
    <DisclaimerIE />
    <Header>
      <Logo src={logoSrc} alt="Challenge IT Logo" />
    </Header>
    <Main>{children}</Main>
    <Footer />
  </>
)

export default Page


const FooterSection = styled.footer`
  width: 100%;
  
  padding: 32px;
  background-color: black;
  color: ${CSS_PROPERTIES.COLORS.gray};
`

const FooterContent = styled.div`
  margin-bottom: 25px;
  max-width: 250px;

  font-size: 14px;
  line-height: 1.5;
  text-align: left;
`

const FooterLogo = styled.img`
  height: 50px;
  margin-bottom: 10px;
`

const FooterRights = styled.div`
  width: 100%;
  
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const FooterLine = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${CSS_PROPERTIES.COLORS.gray};
`

const Footer = () => {
  const { t } = useLangStoreIEData()
  return (
    <FooterSection>
      <FooterContent>
        <FooterLogo src={logoSrc} alt="Challenge IT Logo" />
        <p>{t('footer__aboutUs')}</p>
      </FooterContent>
      <FooterLine />
      <FooterRights>
        <p>{t('footer__rights')}</p>
        <FooterIconLink href={EXTERNAL_LINKS.CHALLENGE_LINKEDIN} Icon={LinkedinSvg} />
      </FooterRights>
    </FooterSection>
  )
}
