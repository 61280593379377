import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'

import './styles/fonts.css'
import './index.css'

import { isIE } from './utils/isIE'
import reportWebVitals from './utils/reportWebVitals'

if (isIE()) {
  require('react-app-polyfill/ie9')
  require('react-app-polyfill/ie11')
  require('./ie/ie-styles.css')

  doRender(require('./ie/HomePageIE').default)
}
else {
  require('./styles/animations.css')
  require('./styles/colors.css')
  require('./styles/utils.css')
  require('./styles/sizes.css')

  doRender(require('./App').default)
}

function doRender(AppComponent) {
  ReactDOM.render(
    <React.StrictMode>
      <AppComponent />
    </React.StrictMode>,
    document.getElementById('root')
  )

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals()
}
