import React from 'react'
import styled from 'styled-components'

import { ROUTES } from '../constants'
import LinkButton from '../widgets/LinkButton'
import { useLangStoreData } from '../utils/LangStore'

const StyledMenu = styled.nav`
  display: flex;

  & > * + * {
    margin-left: 1rem;
  }
`

const Menu = () => {
  const { t } = useLangStoreData()
  return (
    <StyledMenu className='header-menu'>
      <LinkButton href={ROUTES.homePage}>{t('header__menu__home')}</LinkButton>
      <LinkButton href={ROUTES.aboutUsPage}>{t('header__menu__aboutUs')}</LinkButton>
      <LinkButton href={ROUTES.servicesPage}>{t('header__menu__services')}</LinkButton>
      <LinkButton href={ROUTES.processPage}>{t('header__menu__process')}</LinkButton>
      <LinkButton href={ROUTES.contactUsPage}>{t('header__menu__contactUs')}</LinkButton>
    </StyledMenu>
  )
}

export default Menu

const MenuMobileContent = styled.div`
  display: none;

  background: black;
  box-shadow: 0px 10px 16px rgb(0 0 0 / 35%);
  color: white;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding-top: 90px;
`

const MenuMobileItem = styled.a`
  display: block;
  padding: 35px 15px;
  text-align: center;
  font-size: 26px;
  font-weight: 700;
  cursor: pointer;
  width: 180px;
  border-bottom: 2px solid white;
  margin: 0 auto;
`

export const MenuMobile = ({ isOpen }) => {
  const { t } = useLangStoreData()
  return (
    <MenuMobileContent className={`${isOpen ? 'header-menu-mobile--open' : ''}`}>
      <MenuMobileItem href={ROUTES.homePage}>{t('header__menu__home')}</MenuMobileItem>
      <MenuMobileItem href={ROUTES.aboutUsPage}>{t('header__menu__aboutUs')}</MenuMobileItem>
      <MenuMobileItem href={ROUTES.servicesPage}>{t('header__menu__services')}</MenuMobileItem>
      <MenuMobileItem href={ROUTES.processPage}>{t('header__menu__process')}</MenuMobileItem>
      <MenuMobileItem href={ROUTES.contactUsPage}>{t('header__menu__contactUs')}</MenuMobileItem>
    </MenuMobileContent>
  )
}
