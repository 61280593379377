export const ROUTES = {
  homePage: '/',
  aboutUsPage: '/about',
  servicesPage: '/solutions',
  contactUsPage: '/contacts',
  processPage: '/process',
}

export const PAGES = {
  home: '/',
  aboutUs: 'about',
  solutions: 'solutions',
  contactUs: 'contacts',
  process: 'process',
}

export const UI = {
  MAX_WIDTH_SMALL_SCREEN: '48rem',
  TABLET: '768px',
  MOBILE: '480px',
}

export const UTILS = {
  InvalidStoreAccessError: (storeName, storeProviderName) =>
    Error(`${storeName} accessed from component that is not wrapped in a ${storeProviderName}`)
}

