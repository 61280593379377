import React from "react";
import Image from "./Image";
import "./ImageContainer.css";

const ImageContainer = props => {
  return (
    <div
      rel="noopener noreferrer"
      className="image-container"
    >
      <Image src={props.src} thumb={props.thumb} alt={props.alt} />
    </div>
  );
};

export default ImageContainer;
