import React from 'react'
import styled from 'styled-components'

import { CSS_PROPERTIES } from './ie-constants'


const StyledFooterIconLink = styled.a`
  margin: 0.25rem;
  padding: 0.5rem;
  height: 2rem;
  width: 2rem;
  min-width: 2rem;

  & svg {
    fill: ${CSS_PROPERTIES.COLORS.gray};
    height: 1rem;
    width: 1rem;
  }

  &:hover {
    background: ${CSS_PROPERTIES.COLORS.gray};
    border-radius: 10px;
  }

  &:hover svg {
    fill: black;
  }

  &:active {
    background: white;
  }
`

const FooterIconLink = ({ href, Icon }) => (
  <StyledFooterIconLink href={href} target="_blank" rel="noopener">
    <Icon />
  </StyledFooterIconLink>
)

export default FooterIconLink
