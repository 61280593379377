import React from 'react'
import styled from 'styled-components'

import Page from '../components/Page'
import Banner from '../components/Banner'
import { PAGES, ROUTES, UI } from '../constants'
import { useLangStoreData } from '../utils/LangStore'
import PageCTASection from '../components/PageCTASection'

import bannerImg from '../assets/pics/process.png'
import smallBannerImg from '../assets/pics/process-small.png'
import analysisIcon from '../assets/icons/analysis-icon.svg'
import deliveryIcon from '../assets/icons/delivery-icon.svg'
import developmentIcon from '../assets/icons/development-icon.svg'
import validationIcon from '../assets/icons/validation-icon.svg'
import processCycleIcon from '../assets/icons/processCycle.png'

const ProcessBanner = () => {
  const { t } = useLangStoreData()
  return (
    <Banner
      title={t('process__title')}
      subTitle={t('process__subTitle')}
      imgSrc={bannerImg}
      smallImgSrc={smallBannerImg}
    />
  )
}

const ProcessIntroStyled = styled.section`
  background-color: white;
`

const ProcessIntroTitle = styled.div`
  line-height: 1.5;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  margin: 0 auto;
`

const ProcessIntro = () => {
  const { t } = useLangStoreData()
  return (
    <ProcessIntroStyled className='section'>
      <ProcessIntroTitle className='title'>{t('process__intro')}</ProcessIntroTitle>
    </ProcessIntroStyled>
  )
}

const ProcessSteps = styled.section`
  padding-top: 0;
  background-color: white;
  color: var(--color_text_overBgMain);
  display: flex;
  flex-direction: column;
  gap: var(--s2);
`

const ProcessStepsContainer = styled.div``

const ProcessStepTitle = styled.h2`
  font-size: 28px;
  color: var(--color_text_overBgMain);
  font-weight: 700;

  @media (max-width: ${UI.MOBILE}) {
    & {
      font-size: 26px;
    }
  }
`
const ProcessStepContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--s2);
  flex-wrap: wrap;

  font-size: 20px;

  @media (max-width: ${UI.TABLET}) {
    & {
      font-size: 18px;
      flex-direction: column;
    }
  }
`

const ProcessStepImage = styled.img`
  max-width: 260px;
  height: auto;

  @media (max-width: ${UI.MOBILE}) {
    & {
      max-width: 70%;
    }
  }
`

const ProcessStepTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--s0);

  flex: 1;
`

const ProcessStepText = styled.div`
  letter-spacing: 1.6px;
  line-height: 1.5;
`

const ProcessStepImageContainer = styled.div`
  display: flex;
  justify-content:  center;

  flex: 1;
`

const ProcessStepsSection = () => {
  const { t } = useLangStoreData()

  return (
    <ProcessSteps className='section'>
      <ProcessStepsContainer className='container'>
        <ProcessStepContent>
          <ProcessStepTextContainer>
            <ProcessStepImageContainer>
              <ProcessStepImage className='appear-on-tablet' src={analysisIcon} alt='Analysis Icon' />
            </ProcessStepImageContainer>
            <ProcessStepTitle>{'1. ' + t('process__analysisTitle')}</ProcessStepTitle>
            <ProcessStepText>{t('process__analysisText1')}</ProcessStepText>
            <ProcessStepText>{t('process__analysisText2')}</ProcessStepText>
          </ProcessStepTextContainer>
          <ProcessStepImage className='disappear-on-tablet' src={analysisIcon} alt='Analysis Icon' />
        </ProcessStepContent>
      </ProcessStepsContainer>
      <ProcessStepsContainer className='container'>
        <ProcessStepContent>
          <ProcessStepImage className='disappear-on-tablet' src={developmentIcon} alt='Development Icon' />
          <ProcessStepTextContainer>
            <ProcessStepImageContainer>
              <ProcessStepImage className='appear-on-tablet' src={developmentIcon} alt='Development Icon' />
            </ProcessStepImageContainer>
            <ProcessStepTitle>{'2. ' + t('process__developmentTitle')}</ProcessStepTitle>
            <ProcessStepText>{t('process__developmentText1')}</ProcessStepText>
            <ProcessStepText>{t('process__developmentText2')}</ProcessStepText>
          </ProcessStepTextContainer>
        </ProcessStepContent>
      </ProcessStepsContainer>
      <ProcessStepsContainer className='container'>
        <ProcessStepContent>
          <ProcessStepTextContainer>
            <ProcessStepImageContainer>
              <ProcessStepImage className='appear-on-tablet' src={validationIcon} alt='Validation Icon' />
            </ProcessStepImageContainer>
            <ProcessStepTitle>{'3. ' + t('process__validationTitle')}</ProcessStepTitle>
            <ProcessStepText>{t('process__validationText1')}</ProcessStepText>
            <ProcessStepText>{t('process__validationText2')}</ProcessStepText>
          </ProcessStepTextContainer>
          <ProcessStepImage className='disappear-on-tablet' src={validationIcon} alt='Validation Icon' />
        </ProcessStepContent>
      </ProcessStepsContainer>
      <ProcessStepsContainer className='container'>
        <ProcessStepContent>
          <ProcessStepImage className='disappear-on-tablet' src={deliveryIcon} alt='Delivery Icon' />
          <ProcessStepTextContainer>
            <ProcessStepImageContainer>
              <ProcessStepImage className='appear-on-tablet' src={deliveryIcon} alt='Delivery Icon' />
            </ProcessStepImageContainer>
            <ProcessStepTitle>{'4. ' + t('process__deliveryTitle')}</ProcessStepTitle>
            <ProcessStepText>{t('process__deliveryText1')}</ProcessStepText>
            <ProcessStepText>{t('process__deliveryText2')}</ProcessStepText>
          </ProcessStepTextContainer>
        </ProcessStepContent>
      </ProcessStepsContainer>
    </ProcessSteps>
  )
}

const ProcessWrapUp = styled.section`
  font-size: 20px;
  background-color: var(--color_text_overBgMain);
  color: white;
  display: flex;
  flex-direction: column;
  gap: var(--s2);
`

const ProcessWrapUpTitle = styled.div`
  line-height: 1.5;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  width: 60%;
  margin: 0 auto;
`

const ProcessWrapUpContainer = styled.div``

const ProcessWrapUpContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--s1);
  flex-wrap: wrap;

  @media (max-width: ${UI.TABLET}) {
    & {
      font-size: 18px;
      flex-direction: column;
    }
  }
`

const ProcessWrapUpImage = styled.img`
  max-width: 36%;
  height: auto;

  @media (max-width: ${UI.TABLET}) {
    & {
      max-width: 70%;
    }
  }
`

const ProcessWrapUpTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--s0);

  flex: 1;
`

const ProcessWrapUpText = styled.div`
  letter-spacing: 1.6px;
  line-height: 1.5;
`

const CostDurationBreakdownContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: var(--s0);
  row-gap: var(--s2);

  @media (max-width: ${UI.TABLET}) {
    & {
      grid-template-columns: 1fr;
    }
  }
`

const CostDurationBreakdownItem = styled.div`
  max-width: 60ch;
`

const CostDurationBreakdownItemTitle = styled.p`
  font-size: 28px;
  font-weight: 700;
  margin-bottom: var(--s0);
`

const CostDurationBreakdownItemFactorsContainer = styled.ul`
  list-style-position: inside;
  margin-top: var(--s0);
  display: grid;
  grid-template-columns: 1fr;
  row-gap: var(--s0);
`

const CostDurationBreakdownItemFactor = styled.li`
`

const CostDurationBreakdownSection = () => {
  const { t } = useLangStoreData()

  return (
    <CostDurationBreakdownContainer className='container'>
      <CostDurationBreakdownItem>
        <CostDurationBreakdownItemTitle>{t('process__durationTitle')}</CostDurationBreakdownItemTitle>
        <p>{t('process__durationText')}</p>
        <CostDurationBreakdownItemFactorsContainer>
          <CostDurationBreakdownItemFactor>{t('process__durationFactor1')}</CostDurationBreakdownItemFactor>
          <CostDurationBreakdownItemFactor>{t('process__durationFactor2')}</CostDurationBreakdownItemFactor>
          <CostDurationBreakdownItemFactor>{t('process__durationFactor3')}</CostDurationBreakdownItemFactor>
          <CostDurationBreakdownItemFactor>{t('process__durationFactor4')}</CostDurationBreakdownItemFactor>
        </CostDurationBreakdownItemFactorsContainer>
      </CostDurationBreakdownItem>
      <CostDurationBreakdownItem>
        <CostDurationBreakdownItemTitle>{t('process__costTitle')}</CostDurationBreakdownItemTitle>
        <p>{t('process__costText')}</p>
        <CostDurationBreakdownItemFactorsContainer>
          <CostDurationBreakdownItemFactor>{t('process__costFactor1')}</CostDurationBreakdownItemFactor>
          <CostDurationBreakdownItemFactor>{t('process__costFactor2')}</CostDurationBreakdownItemFactor>
          <CostDurationBreakdownItemFactor>{t('process__costFactor3')}</CostDurationBreakdownItemFactor>
          <CostDurationBreakdownItemFactor>{t('process__costFactor4')}</CostDurationBreakdownItemFactor>
        </CostDurationBreakdownItemFactorsContainer>
      </CostDurationBreakdownItem>
    </CostDurationBreakdownContainer>
  )
}

const ProcessWrapUpSection = () => {
  const { t } = useLangStoreData()

  return (
    <ProcessWrapUp className='section'>
      <ProcessWrapUpTitle>An overview</ProcessWrapUpTitle>
      <ProcessWrapUpContainer className='container'>
        <ProcessWrapUpContent>
          <ProcessWrapUpTextContainer>
            <ProcessWrapUpText>{t('process__wrapUpText1')}</ProcessWrapUpText>
            <ProcessWrapUpText>{t('process__wrapUpText2')}</ProcessWrapUpText>
            <ProcessWrapUpImage className='appear-on-tablet' style={{ marginTop: 'var(--s0)', marginBottom: 'var(--s0)' }} src={processCycleIcon} alt='Process Cycle Icon' />
            <ProcessWrapUpText>{t('process__wrapUpText3')}</ProcessWrapUpText>
            <ProcessWrapUpText>{t('process__wrapUpText4')}</ProcessWrapUpText>
          </ProcessWrapUpTextContainer>
          <ProcessWrapUpImage className='disappear-on-tablet' src={processCycleIcon} alt='Process Cycle Icon' />
        </ProcessWrapUpContent>
      </ProcessWrapUpContainer>
      <CostDurationBreakdownSection />
    </ProcessWrapUp>
  )
}

const ProcessCTASection = () => {
  const { t } = useLangStoreData()
  return (
    <PageCTASection
      title={t('process__ctaTitle')}
      buttonText={t('process__ctaButton')}
      url={ROUTES.contactUsPage}
    />
  )
}

const ProcessPage = () => {
  const { t } = useLangStoreData()
  return (
    <Page active={PAGES}>
      <ProcessBanner />
      <ProcessIntro />
      <ProcessStepsSection />
      <ProcessWrapUpSection />
      <ProcessCTASection />
    </Page>
  )
}

export default ProcessPage
